.TitleService {
    color: #07B9F1;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 1;
    font-weight: 700;
}

.SubitleService {
    color: #8E8F92;
    text-transform: uppercase;
    line-height: 1;
}




.btn-lopez {
    color: white;
    background-color: #07B9F1;
    border-color: #07B9F1;
    text-transform: uppercase;
    font-weight: 700;
    font-size: .7rem;
}

.btn-lopez:hover, .btn-lopez:focus, .btn-lopez:active{
    color: white !important;
    background-color: #07B9F1 !important;
    border-color: #07B9F1 !important;
}