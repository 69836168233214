.sectionTitle {
    font-size: 35px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1;
    display: flex;
}

.paralelogramo {
    width: 15px; 
    height: 75px; 
    background: #007CA3;
    -webkit-transform: skew(345deg);
    -moz-transform: skew(345deg);
    -ms-transform: skew(345deg);
    -o-transform: skew(345deg);
    transform: skew(345deg);
}


