.bg-graylo {
    background-color: #8E8F92;
}

.bg-bluelo {
    background-color: #007CA3;
}

.bg-greenlo {
    background-color: #00A340;
}

.footertxt {
    text-transform: uppercase;
    font-size: .8rem;
    color: white;
}